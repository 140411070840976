import React from "react";

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import AppBar from "@mui/material/AppBar";
import Avatar from "@mui/material/Avatar";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import Menu from "@mui/icons-material/Menu";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import SearchBar from "./SearchBar.js";
import UserContext from "../contexts/UserContext.js";

const TopBar = ({ onClick }) => {
  const { user } = React.useContext(UserContext);
  const ultraNarrow = useMediaQuery("(max-width:399px)");

  //---------------------------------------------------------------------------
  // Render
  //---------------------------------------------------------------------------
  return (
    <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
      <Toolbar>
        <Grid container spacing={3} justifyContent="space-between" alignItems="center">
          <Grid item xs="auto">
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
              onClick={onClick}
            >
              <Menu />
            </IconButton>
          </Grid>
          <Grid item xs="auto" sx={{ display: { xs: "none", sm: "block" } }}>
            <Typography variant="h6" noWrap component="div">
              BitRhythm Dev Dashboard
            </Typography>
          </Grid>
          <Grid item xs>
            <SearchBar />
          </Grid>
          {!ultraNarrow && (
            <Grid item xs="auto">
              <Avatar alt={user.name} src={user.picture} />
            </Grid>
          )}
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default TopBar;
