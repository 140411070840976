import React from "react";
import PropTypes from "prop-types";
import { Link, useLocation } from "react-router-dom";

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

const ItemButton = ({ Icon, text, to }) => {
  // Use location to highlight when our link matches the current page
  const location = useLocation();

  //---------------------------------------------------------------------------
  // Render
  //---------------------------------------------------------------------------
  return (
    <ListItemButton component={Link} to={to} selected={location.pathname === to}>
      <ListItemIcon sx={{ color: "text.secondary" }}>{Icon}</ListItemIcon>
      <ListItemText primary={text} />
    </ListItemButton>
  );
};

export default ItemButton;

ItemButton.propTypes = {
  Icon: PropTypes.object.isRequired,
  text: PropTypes.string.isRequired,
  to: PropTypes.string,
  id: PropTypes.string,
  onClick: PropTypes.func,
};
