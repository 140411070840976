import React from "react";

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import { styled } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import SideBar from "./SideBar.js";
import TopBar from "./TopBar.js";
import { SearchProvider } from "../contexts/SearchContext.js";

const DRAWER_WIDTH = 240;

//---------------------------------------------------------------------------
// Styled component that contains the main content of the page below the sidebar,
// acts as a wrapper for the content that gets slid to the right
// when the side bar is opened
//---------------------------------------------------------------------------
const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open, mobile }) => ({
    flexGrow: 1,
    padding: theme.spacing(1),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: 0,
    width: "100%",
    ...(open &&
      !mobile && {
        transition: theme.transitions.create("margin", {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: `${DRAWER_WIDTH}px`,
        width: `calc(100% - ${DRAWER_WIDTH}px)`,
      }),
  })
);

const Layout = ({ children }) => {
  // Convert true/false to 1/0 to avoid console errors
  const mobile = useMediaQuery("(max-width:899px)") ? 1 : 0;

  const [open, setOpen] = React.useState(true);
  const [search, setSearch] = React.useState("");

  //---------------------------------------------------------------------------
  // Render
  //---------------------------------------------------------------------------
  return (
    <SearchProvider search={search} setSearch={setSearch}>
      <CssBaseline />
      <TopBar onClick={() => setOpen((prev) => !prev)} />
      <Drawer
        sx={{
          width: DRAWER_WIDTH,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: DRAWER_WIDTH,
            boxSizing: "border-box",
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <Toolbar />
        <SideBar />
      </Drawer>
      <Toolbar />
      <Main open={open} mobile={mobile}>
        {children}
      </Main>
    </SearchProvider>
  );
};

export default Layout;
