import React, { createContext } from "react";
import { useLocation } from "react-router-dom";

const SearchContext = createContext();

export const SearchProvider = ({ search, setSearch, children }) => {
  // Reset the search bar every time we navigate to a new page
  const location = useLocation();
  React.useEffect(() => {
    setSearch("");
  }, [location, setSearch]);

  return <SearchContext.Provider value={{ search, setSearch }}>{children}</SearchContext.Provider>;
};

export default SearchContext;
