/* eslint-env browser */
import React from "react";
import axios from "axios";

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Check from "@mui/icons-material/Check";
import DesktopAccessDisabled from "@mui/icons-material/DesktopAccessDisabled";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import useInterval from "@tzmedical/react-hooks/useInterval";

const DATA_REFRESH_INTERVAL_MS = 15000;

const Url = ({
  // Props
  address,
  name = address,
  livenessAddress = `${address}/buildIdentifier`,
}) => {
  //---------------------------------------------------------------------------
  // State for tracking service status
  //---------------------------------------------------------------------------
  const [serviceStatus, setServiceStatus] = React.useState("Loading");

  //---------------------------------------------------------------------------
  // The check function is a React callback so we can pass it to useInterval
  //---------------------------------------------------------------------------
  const getServiceStatus = React.useCallback(async () => {
    try {
      if (livenessAddress) {
        await axios({
          url: livenessAddress,
          method: "GET",
        });
        setServiceStatus("Up");
      }
    } catch (error) {
      setServiceStatus("Down");
    }
  }, [livenessAddress]);

  //---------------------------------------------------------------------------
  // Fetch now and then periodically re-fetch
  //---------------------------------------------------------------------------
  React.useEffect(() => {
    getServiceStatus();
  }, [getServiceStatus]);
  useInterval(getServiceStatus, DATA_REFRESH_INTERVAL_MS);

  //---------------------------------------------------------------------------
  // Render
  //---------------------------------------------------------------------------
  return (
    <Typography variant="h5">
      {livenessAddress && (
        <>
          {serviceStatus === "Up" && <Check color="success" fontSize="inherit" />}
          {serviceStatus === "Down" && <DesktopAccessDisabled color="error" fontSize="inherit" />}
          {serviceStatus === "Loading" && <CircularProgress size="1.25rem" />}
        </>
      )}
      <a href={address} target="_blank" rel="noreferrer">
        {name}
      </a>
      <br />
    </Typography>
  );
};

export default Url;
