/* eslint-env browser */
import React from "react";
import PropTypes from "prop-types";
import md5 from "crypto-js/md5";

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import { Avatar } from "@mui/material";

const GravatarImage = ({ userEmail, disabled, style }) => {
  // Cloud Storage Static Default File
  const defaultImageLink = "https://storage.googleapis.com/bitrhythm-static-files/fallbackUser.png";

  // Fetches Gravatar
  const getAvatarUrlWithFallback = React.useCallback(() => {
    const userHash = md5(userEmail.trim().toLowerCase());
    const fallbackImage = encodeURIComponent(defaultImageLink);
    return `https://www.gravatar.com/avatar/${userHash}?d=${fallbackImage}`;
  }, [userEmail]);

  // Makes the avatar a light grey if the disabled prop passed through is 'true'
  if (disabled) {
    style.filter = "grayscale(100%) opacity(0.5)";
  }

  //---------------------------------------------------------------------------
  // Render
  //---------------------------------------------------------------------------
  return <Avatar style={style} src={getAvatarUrlWithFallback()} />;
};

GravatarImage.propTypes = {
  userEmail: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  style: PropTypes.object,
};

export default GravatarImage;
