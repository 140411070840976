/* eslint-env browser */
import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import { Container } from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import { Button } from "@mui/material";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import Layout from "./components/Layout.js";
import TableLoading from "./components/PageLoading.js";
import { UserProvider } from "./contexts/UserContext.js";

//---------------------------------------------------------------------------
// BitRhythm Pages
//---------------------------------------------------------------------------
import DeveloperStacks from "./pages/DeveloperStacks.js";
import PublicStacks from "./pages/PublicStacks.js";
import Page404 from "./pages/Page404.js";

function App() {
  const { isLoading, isAuthenticated, error, user, loginWithRedirect } = useAuth0();

  React.useEffect(() => {
    if (!isLoading && !isAuthenticated && !error) {
      // Use pathname for redirect - since we're staying within the application
      loginWithRedirect({ appState: { returnTo: window.location.origin } });
    }
  }, [isLoading, isAuthenticated, error, loginWithRedirect]);

  //---------------------------------------------------------------------------
  // Render
  //---------------------------------------------------------------------------
  return (
    <div className="App">
      <header className="App-header">
        {error && (
          <Container maxWidth="lg">
            <div>Oops... {error.message}</div>
            <br />
            <Button
              variant="contained"
              onClick={() =>
                loginWithRedirect({
                  appState: { returnTo: window.location.origin },
                })
              }
            >
              Login
            </Button>
          </Container>
        )}
        {!error && (isLoading || !isAuthenticated) && <TableLoading />}
        {isAuthenticated && (
          <BrowserRouter>
            <UserProvider user={user}>
              <Layout>
                <Routes>
                  <Route path="/" element={<DeveloperStacks />} />
                  <Route path="/public" element={<PublicStacks />} />
                  <Route path="*" element={<Page404 />} />
                </Routes>
              </Layout>
            </UserProvider>
          </BrowserRouter>
        )}
      </header>
    </div>
  );
}

export default App;
