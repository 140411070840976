import React from "react";

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import { List } from "@mui/material";
import { Person, Public } from "@mui/icons-material";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import NavButton from "./NavButton.js";

const TableLoading = () => {
  //---------------------------------------------------------------------------
  // Render
  //---------------------------------------------------------------------------
  return (
    <List>
      <NavButton Icon={<Person />} text="User Stacks" to="/" />
      <NavButton Icon={<Public />} text="Public Stacks" to="/public" />
    </List>
  );
};

export default TableLoading;
