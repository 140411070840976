/* eslint-env browser */
import React from "react";
import axios from "axios";

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import StarRate from "@mui/icons-material/StarRate";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import GravatarImage from "./GravatarImage.js";
import Url from "./Url.js";
import UserContext from "../contexts/UserContext.js";
import useInterval from "@tzmedical/react-hooks/useInterval";

const DATA_REFRESH_INTERVAL_MS = 15000;

const Stack = ({
  //Props
  stack,
}) => {
  const { user } = React.useContext(UserContext);
  const stackEmail = `${stack.suffix}@tzmedical.com`;

  // Add the '-' for the suffixes
  const suffix = stack?.suffix !== "" ? `-${stack.suffix}` : "";
  const { Icon } = stack;

  //---------------------------------------------------------------------------
  // URLs to check
  //---------------------------------------------------------------------------
  const apiUrl = stack?.urls?.api ?? `https://api${suffix}.${stack.domain}`;
  const adminUrl = stack?.urls?.admin ?? `https://admin${suffix}.${stack.domain}`;
  const inboxUrl = stack?.urls?.inbox ?? `https://inbox${suffix}.${stack.domain}`;
  const phpMyAdminUrl = stack?.urls?.phpMyAdmin ?? `https://phpmyadmin${suffix}.${stack.domain}`;

  const defaultSwaggerUrl = React.useMemo(
    () => `https://petstore.swagger.io/?url=${encodeURIComponent(apiUrl)}`,
    [apiUrl]
  );
  const [swaggerUrl, setSwaggerUrl] = React.useState(defaultSwaggerUrl);
  const [stackVersion, setStackVersion] = React.useState();

  //---------------------------------------------------------------------------
  // The check functions are React callbacks so we can pass them to useInterval
  //---------------------------------------------------------------------------
  const getStackVersion = React.useCallback(async () => {
    try {
      const { data } = await axios({
        url: `${apiUrl}/configuration/softwareVersion`,
        method: "GET",
      });
      setStackVersion(data);
    } catch (error) {
      setStackVersion(undefined);
    }
  }, [apiUrl]);

  const getSwaggerUrl = React.useCallback(async () => {
    try {
      const {
        data: {
          info: { readme },
        },
      } = await axios({
        url: apiUrl,
        method: "GET",
      });
      setSwaggerUrl(readme || defaultSwaggerUrl);
    } catch (error) {
      setSwaggerUrl(defaultSwaggerUrl);
    }
  }, [apiUrl, defaultSwaggerUrl]);

  //---------------------------------------------------------------------------
  // Fetch now and then periodically re-fetch
  //---------------------------------------------------------------------------
  React.useEffect(() => {
    getStackVersion();
    getSwaggerUrl();
  }, [getStackVersion, getSwaggerUrl]);
  useInterval(() => {
    getStackVersion();
    getSwaggerUrl();
  }, DATA_REFRESH_INTERVAL_MS);

  //---------------------------------------------------------------------------
  // Render
  //---------------------------------------------------------------------------
  return (
    <Card sx={{ p: 3 }}>
      <Grid container alignItems="center" justifyContent="center" spacing={2}>
        <Grid item>
          {!Icon && <GravatarImage userEmail={stackEmail} />}
          {!!Icon && <Icon color="primary" sx={{ fontSize: "3rem" }} />}
        </Grid>
        <Grid item>
          <Typography variant="h4" noWrap component="h1">
            {`${stack.name}`}
          </Typography>
        </Grid>
        {user.email === stackEmail && (
          <Grid item>
            <StarRate sx={{ fontSize: "2rem" }} color="primary" />
          </Grid>
        )}
      </Grid>
      <Typography variant="subtitle1" noWrap component="h2">
        <i>{stackVersion || <br />}</i>
      </Typography>
      <Url address={swaggerUrl} livenessAddress={`${apiUrl}/buildIdentifier`} name="API Gateway" />
      <Url address={adminUrl} name="Admin" />
      <Url address={inboxUrl} name="Inbox" />
      <Url
        address={phpMyAdminUrl}
        livenessAddress={`${phpMyAdminUrl}/robots.txt`}
        name="PHPMyAdmin"
      />
    </Card>
  );
};

export default Stack;
