import React from "react";

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import { CircularProgress, Grid } from "@mui/material";

const TableLoading = () => {
  //---------------------------------------------------------------------------
  // Render
  //---------------------------------------------------------------------------
  return (
    <Grid container>
      <Grid item sx={{ mt: 10 }} xs={12} align="center">
        <CircularProgress size={150} />
      </Grid>
    </Grid>
  );
};

export default TableLoading;
